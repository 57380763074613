import React, { useContext, useEffect, useState } from "react";
import useStyles from "./style";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import { useHistory } from "react-router-dom";
import { getAllBrandsRequest } from "../../../api/brand.api";
import useCancellablePromise from "../../../api/cancelRequest";
import no_image_found from "../../../assets/images/no_image_found.png";
import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as PreviousIcon } from "../../../assets/images/previous.svg";
import { ReactComponent as NextIcon } from "../../../assets/images/next.svg";

import Loading from "../../shared/loading/loading";
import { baseUrl } from "../../../api/apis";
import { AddressContext } from "../../../context/addressContext";
import { postCall } from "../../../api/axios";

const BrandCard = ({ data, index, onMouseOver }) => {
  const classes = useStyles();
  const history = useHistory();
  const { _id: id, name: brand_name, photo: symbol } = data;
  // const { name: brand_name, symbol } = descriptor;

  return (
    <>
      <Tooltip title={brand_name}>
        <Card
          className={classes.brandCard}
          onMouseOver={onMouseOver}
          onClick={() => history.push(`/application/brand?brandId=${id}`)}
        >
          <img
            className={classes.brandImage}
            src={symbol ? symbol : no_image_found}
            alt={`brand-${index}`}
          />
        </Card>
      </Tooltip>
    </>
  );
};

const TopBrands = () => {
  const classes = useStyles();
  const [activeBrandIndex, setActiveBrandIndex] = useState(1);
  const [brands, setBrands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { deliveryAddress } = useContext(AddressContext);

  const dispatch = useContext(ToastContext);

  function handleResize() {
    const width = window.innerWidth;
    setScreenWidth(width);
  }
  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const getAllBrands = async (searchName) => {
    setIsLoading(true);
    try {
      const {
        location: {
          address: {
            zipcode,
            region: { latitude, longitude },
          },
        },
      } = deliveryAddress;
      const url = `${baseUrl}/api/get-sellers`;
      const params = {
        areaCode: zipcode,
        latitude,
        longitude,
        // "category": "Grocery"
        // 12.9566° N, 77.7047
      };
      const data = await postCall(url, params);
      console.log("url, params  ==>", data);
      data && data["sellers"] && setBrands(data["sellers"]);
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {}, []);

  const rowsPerPage = parseInt(screenWidth / 120) - 7;
  const totalPageCount = Math.ceil(brands.length / rowsPerPage);
  return (
    <Grid container spacing={3} className={classes.topBrandsContainer}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="h4">All Providers</Typography>
      </Grid>
      {isLoading ? (
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.brandsContainer}
        >
          <Loading />
        </Grid>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.brandsContainer}
          >
            <div
              style={{
                marginLeft: "auto",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <IconButton
                color="inherit"
                className={classes.actionButton}
                onClick={() => {
                  setPage(page - 1);
                  // setActiveSubCatIndex(activeSubCatIndex-1)
                }}
                disabled={page === 0}
              >
                <PreviousIcon />
              </IconButton>
            </div>
            {brands
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((brand, brandIndex) => (
                <BrandCard
                  key={`btand-index-${brandIndex}`}
                  data={brand}
                  index={brandIndex}
                  // isActive={brandIndex === activeBrandIndex}
                  onMouseOver={() => {
                    setActiveBrandIndex(brandIndex);
                  }}
                />
              ))}
            <div
              style={{
                marginRight: "auto",
                marginTop: "auto",
                marginBottom: "auto",
              }}
            >
              <IconButton
                color="inherit"
                className={classes.actionButton}
                onClick={() => {
                  setPage(page + 1);
                  // setActiveSubCatIndex(activeSubCatIndex+1)
                }}
                disabled={page === totalPageCount - 1}
              >
                <NextIcon />
              </IconButton>
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default TopBrands;
