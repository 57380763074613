import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./Products/products.slice";
import loadersReducer from "./Loaders/loaders.slice";
import sellersReducer from "./Sellers/sellers.slice";

export const store = configureStore({
  reducer: {
    products: productsReducer,
    loaders: loadersReducer,
    sellers: sellersReducer,
  },
});
