import { createContext, useEffect, useState } from "react";
import { getValueFromCookie } from "../utils/cookies";

export const AddressContext = createContext({
  deliveryAddress: {},
  setDeliveryAddress: () => {},
  billingAddress: {},
  setBillingAddress: () => {},
});

export function AddressContextProvider({ children }) {
  const [deliveryAddress, setDeliveryAddress] = useState();
  const [billingAddress, setBillingAddress] = useState();

  useEffect(() => {
    const delievery_address_cookie = getValueFromCookie("delivery_address");
    const billing_address_cookie = getValueFromCookie("billing_address");
    if (delievery_address_cookie) {
      try {
        let value = JSON.parse(delievery_address_cookie);
        if (value) {
          setDeliveryAddress(value);
        }
      } catch (error) {
        console.log("Error at address context :>", error);
      }
    }
    if (billing_address_cookie) {
      try {
        let value = JSON.parse(billing_address_cookie);
        if (value) {
          setBillingAddress(value);
        }
      } catch (error) {
        console.log("Error at address context :>", error);
      }
    }
  }, []);

  return (
    <AddressContext.Provider
      value={{
        deliveryAddress,
        setDeliveryAddress,
        billingAddress,
        setBillingAddress,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}
