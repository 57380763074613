import { getCall } from "./axios";

/**
 * function to get all products
 * @returns
 */
export const getAllDeliveryAddressRequest = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v1/delivery_address`);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getUserAddresses = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/get-address?userId=${userId}`;
      const data = await getCall(url);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};
