import React, { useContext } from "react";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "../../../common/Radio";

import { AddressContext } from "../../../../context/addressContext";
import useCancellablePromise from "../../../../api/cancelRequest";
import axios from "axios";
import { AddCookie, removeCookie } from "../../../../utils/cookies";
import useStyles from "./style";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { ToastContext } from "../../../../context/toastContext";
import { toast_actions, toast_types } from "../../../shared/toast/utils/toast";

const SelectAddress = ({
  addresses,
  onClose,
  setAddAddress,
  setUpdateAddress,
  onSelectAddress,
}) => {
  const classes = useStyles();
  const { deliveryAddress, setDeliveryAddress, setBillingAddress } =
    useContext(AddressContext);
  const dispatch = useContext(ToastContext);

  // HOOKS
  const { cancellablePromise } = useCancellablePromise();

  const onSetDeliveryAddress = (id, address) => {
    // fetchLatLongFromEloc(address?.areaCode);
    return {
      addressId: id,
      name: address?.contactName || "",
      email: address?.emailId || "",
      phone: address?.contactPhone || "",
      location: {
        address,
      },
    };
  };

  // use this function to fetch lat and long from eloc
  async function fetchLatLongFromEloc(eloc) {
    try {
      const { data } = await cancellablePromise(
        axios.get(
          `${process.env.REACT_APP_MMI_BASE_URL}mmi/api/mmi_place_info?eloc=${eloc}`
        )
      );
      const { latitude, longitude } = data;
      if (latitude && longitude) {
        AddCookie("LatLongInfo", JSON.stringify({ latitude, longitude }));
      } else {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message:
              "Cannot get latitude and longitude info for this pincode Please update the Address",
          },
        });
        setDeliveryAddress({});
      }
    } catch (err) {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err?.response?.data?.error?.message,
        },
      });
    }
  }

  return (
    <Grid container spacing={3}>
      {/* delivery address list card */}
      {addresses && addresses.length > 0 && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FormControl className={classes.formControlRoot}>
            {addresses
              .filter(
                (delivery_address) =>
                  delivery_address?.userId !== "" &&
                  delivery_address?.emailId !== ""
              )
              .map((delivery_address, ind) => {
                const { addressId: id } = delivery_address;
                return (
                  <div
                    key={`address-radio-button-${ind}`}
                    className={classes.selectAddressRadioContainer}
                  >
                    <FormControlLabel
                      className={classes.formControlLabel}
                      onClick={(e) => {
                        e.preventDefault();
                        setDeliveryAddress((pre) =>
                          onSetDeliveryAddress(id, delivery_address)
                        );
                        AddCookie(
                          "delivery_address",
                          JSON.stringify(
                            onSetDeliveryAddress(id, delivery_address)
                          )
                        );
                        setBillingAddress();
                        removeCookie("billing_address");
                        onSelectAddress(
                          onSetDeliveryAddress(id, delivery_address)
                        );
                        onClose();
                      }}
                      control={
                        <Radio checked={deliveryAddress?.addressId === id} />
                      }
                      label={
                        <div>
                          <Typography variant="h5">
                            {delivery_address?.contactName}
                          </Typography>
                          <Typography variant="body1">
                            {delivery_address?.emailId} -{" "}
                            {delivery_address?.contactPhone}
                          </Typography>
                          <Typography variant="body1">
                            {delivery_address?.street
                              ? delivery_address.street
                              : delivery_address?.address_line1}
                            , {delivery_address?.city} {delivery_address?.state}
                          </Typography>
                          <Typography variant="body1">
                            {delivery_address?.zipcode}
                          </Typography>
                        </div>
                      }
                    />
                    <Button
                      className={classes.editAddressButton}
                      variant="text"
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setUpdateAddress({
                          id,
                          _id: id,
                          name: delivery_address?.contactName,
                          email: delivery_address?.emailId,
                          phone: delivery_address?.contactPhone,
                          areaCode: delivery_address?.zipcode,
                          city: delivery_address?.city,
                          door: delivery_address?.address_line1,
                          state: delivery_address?.state,
                          street: delivery_address?.address_line2,
                          tag: delivery_address?.tag
                            ? delivery_address?.tag
                            : "",
                          lat: delivery_address?.region?.latitude,
                          lng: delivery_address?.region?.longitude,
                        });
                      }}
                    >
                      edit
                    </Button>
                  </div>
                );
              })}
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.addAddressContainer}
      >
        <Typography
          variant="h6"
          color="primary"
          className={classes.addAddress}
          onClick={() => setAddAddress()}
        >
          <AddRoundedIcon className={classes.addIcon} />
          Add Address
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SelectAddress;
