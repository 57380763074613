import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    productsLoading: false,
  },
};

export const loadersSlice = createSlice({
  name: "loaders",
  initialState,
  reducers: {
    lodingTrue: (state, { action, payload }) => {
      state.value.productsLoading = true;
      console.log("state.value :", state.value);
    },
    lodingFalse: (state, { action, payload }) => {
      state.value.productsLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { lodingTrue, lodingFalse } = loadersSlice.actions;

export default loadersSlice.reducer;
