import React, { useContext, useEffect, useRef, useState } from "react";
import useStyles from "./styles";
import { useHistory, Link } from "react-router-dom";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Divider,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { deleteCall, getCall, postCall, putCall } from "../../../api/axios";
import { AddCookie, getValueFromCookie } from "../../../utils/cookies";
import Loading from "../../shared/loading/loading";
import { constructQouteObject } from "../../../api/utils/constructRequestObject";
import useCancellablePromise from "../../../api/cancelRequest";
import { SSE_TIMEOUT } from "../../../constants/sse-waiting-time";
import { v4 as uuidv4 } from "uuid";
import { AddressContext } from "../../../context/addressContext";
import { CartContext } from "../../../context/cartContext";
import EditCustomizations from "./EditCustomizations";
import { ToastContext } from "../../../context/toastContext";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import { SearchContext } from "../../../context/searchContext";
import { getFromLocalParsed } from "../../../utils/localStore";
import { defaultCatImg } from "../../../utils/styles";
import { baseUrl } from "../../../api/apis";
import { validateValue } from "../../../utils/validateValues";
import {
  AccordianHead,
  AccordianInfo,
  AccordianBox,
} from "../../shared/accordian/AccordianBox";

var delivery_address = getValueFromCookie("delivery_address");
delivery_address = delivery_address ? JSON.parse(delivery_address) : null;

export default function Cart({ showOnlyItems = false, setCheckoutCartItems }) {
  var user = getValueFromCookie("user");
  user = user ? JSON.parse(user) : null;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useContext(ToastContext);
  const { deliveryAddress } = useContext(AddressContext);
  const {
    fetchCartItems,
    xUpdateCartItem,
    xDeleteCartItem,
    cartItems,
    setCartItems,
  } = useContext(CartContext);
  const { locationData: deliveryAddressLocation } = useContext(SearchContext);

  const { cancellablePromise } = useCancellablePromise();
  const transaction_id = localStorage.getItem("transaction_id");

  const responseRef = useRef([]);
  const eventTimeOutRef = useRef([]);
  const updatedCartItems = useRef([]);
  const [getQuoteLoading, setGetQuoteLoading] = useState(true);
  const [toggleInit, setToggleInit] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [formattedCartItems, setformattedCartItems] = useState([]);
  const [providers, setProviders] = useState({});
  const [loading, setLoading] = useState(false);
  // const [cartItems, setCartItems] = useState([]);
  const [haveDistinctProviders, setHaveDistinctProviders] = useState(false);
  const [errorMessageTimeOut, setErrorMessageTimeOut] = useState(
    "Fetching details for this product"
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [productPayload, setProductPayload] = useState(null);
  const [customization_state, setCustomizationState] = useState({});
  const [productLoading, setProductLoading] = useState(false);
  const [currentCartItem, setCurrentCartItem] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [
    isProductAvailableQuantityIsZero,
    setIsProductAvailableQuantityIsZero,
  ] = useState(false);
  const [isProductCategoryIsDifferent, setIsProductCategoryIsDifferent] =
    useState(false);

  const getCartSubtotal = () => {
    let total = cartItems?.reduce(
      (accumulator, { product: { subtotal } }) =>
        accumulator + parseInt(subtotal),
      0
    );

    return total;
  };

  const checkDistinctProviders = () => {
    if (cartItems.length < 2) {
      setHaveDistinctProviders(false);
    } else {
      const firstProvider = cartItems[0]?.provider?.id;
      let haveDifferentProvider = false;
      for (let i = 1; i < cartItems.length; i++) {
        let cur_provider = cartItems[i]?.provider?.id;
        if (cur_provider !== firstProvider) {
          haveDifferentProvider = true;
          break;
        }
      }
      setHaveDistinctProviders(haveDifferentProvider);
    }
  };

  const formatCartItems = () => {
    let formatMap = new Map();
    let providersObj = {};
    if (cartItems?.length) {
      cartItems?.forEach((item, ind) => {
        let { id: cur_provider, name: provider_name } = item?.provider;
        if (!formatMap.has(cur_provider)) {
          providersObj = { ...providersObj, [cur_provider]: provider_name };
          formatMap.set(cur_provider, [item]);
        } else if (formatMap.has(cur_provider)) {
          formatMap.set(cur_provider, [...formatMap.get(cur_provider), item]);
        }
      });
    }
    let formattedItems = [];
    if (formatMap?.size) {
      formatMap?.forEach((value, key, map) => {
        formattedItems?.push({ providerId: key, items: value });
      });
    }
    if (formattedItems?.length) {
      setformattedCartItems([...formattedItems]);
    }
    if (
      typeof providersObj === "object" &&
      Object.values(providersObj)?.length
    ) {
      setProviders({ ...providersObj });
    }
  };

  const getCartItems = async () => {
    try {
      setLoading(true);
      // const url = `/clientApis/v2/cart/${user.id}`;
      // const res = await getCall(url);
      const local_cart = getFromLocalParsed("cart") ?? [];
      console.log(local_cart);
      setCartItems((pre) => {
        return [...local_cart];
      });
      // updatedCartItems.current = res;
      if (setCheckoutCartItems) {
        setCheckoutCartItems([...local_cart]);
      }
      updatedCartItems.current = local_cart;
      // if (setCheckoutCartItems) {
      //   setCheckoutCartItems(local_cart);
      // }
    } catch (error) {
      console.log("Error fetching cart items:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // function to dispatch error
  function dispatchError(message) {
    dispatch({
      type: toast_actions.ADD_TOAST,
      payload: {
        id: Math.floor(Math.random() * 100),
        type: toast_types.error,
        message,
      },
    });
  }

  const updateCartItem = async (itemId, increment, uniqueId) => {
    const url = `/clientApis/v2/cart/${user.id}/${uniqueId}`;
    const items = cartItems.concat([]);
    const itemIndex = items.findIndex((item) => item._id === uniqueId);
    if (itemIndex !== -1) {
      let updatedCartItem = items[itemIndex];
      updatedCartItem.id = updatedCartItem.item.id;

      if (increment !== null) {
        if (increment) {
          const productMaxQuantity =
            updatedCartItem?.item?.product?.quantity?.maximum;
          if (productMaxQuantity) {
            if (
              updatedCartItem.item.quantity.count < productMaxQuantity.count
            ) {
              updatedCartItem.item.quantity.count += 1;

              let customisations = updatedCartItem.item.customisations;

              if (customisations) {
                customisations = customisations.map((c) => {
                  return {
                    ...c,
                    quantity: { ...c.quantity, count: c.quantity.count + 1 },
                  };
                });

                updatedCartItem.item.customisations = customisations;
              } else {
                updatedCartItem.item.customisations = null;
              }

              updatedCartItem = updatedCartItem.item;

              const res = await putCall(url, updatedCartItem);
              setLoading(false);
              getCartItems();
              fetchCartItems();
            } else {
              dispatchError(
                `Maximum allowed quantity is ${updatedCartItem.item.quantity.count}`
              );
            }
          } else {
            updatedCartItem.item.quantity.count += 1;
            updatedCartItem = updatedCartItem.item;
            const res = await putCall(url, updatedCartItem);
            setLoading(false);
            getCartItems();
            fetchCartItems();
          }
        } else {
          if (updatedCartItem.item.quantity.count > 1) {
            updatedCartItem.item.quantity.count -= 1;

            let customisations = updatedCartItem.item.customisations;

            if (customisations) {
              customisations = customisations.map((c) => {
                return {
                  ...c,
                  quantity: { ...c.quantity, count: c.quantity.count - 1 },
                };
              });
              updatedCartItem.item.customisations = customisations;
            } else {
              updatedCartItem.item.customisations = null;
            }

            updatedCartItem = updatedCartItem.item;
            const res = await putCall(url, updatedCartItem);
            setLoading(false);
            getCartItems();
            fetchCartItems();
          }
        }
      }
    }
  };

  const deleteCartItem = async (itemId) => {
    const url = `/clientApis/v2/cart/${user.id}/${itemId}`;
    const res = await deleteCall(url);
    getCartItems();
    fetchCartItems();
  };

  //   const getProductDetails = async (productId) => {
  //     try {
  //       setProductLoading(true);
  //       const data = await cancellablePromise(getCall(`/clientApis/v2/items/${productId}`));
  //       setProductPayload(data.response);
  //       return data.response;
  //     } catch (error) {
  //       console.error("Error fetching product details:", error);
  //     } finally {
  //       setProductLoading(false);
  //     }
  //   };

  const getProductDetails = async (productId) => {
    try {
      setProductLoading(true);
      const data = await cancellablePromise(
        getCall(`/protocol/item-details?id=${productId}`)
      );
      setProductPayload(data);
      return data;
    } catch (error) {
      console.error("Error fetching product details:", error);
    } finally {
      setProductLoading(false);
    }
  };

  useEffect(() => {
    getCartItems();
  }, [openDrawer]);

  const checkAvailableQuantity = () => {
    let quantityIsZero = false;
    cartItems.forEach((item) => {
      const availableQuantity = item?.product?.quantity?.available;
      if (availableQuantity && availableQuantity?.count === 0) {
        quantityIsZero = true;
      }
    });
    setIsProductAvailableQuantityIsZero(quantityIsZero);
  };

  const checkDifferentCategory = () => {
    const everyEnvHasSameValue = cartItems.every(
      ({ domain }) => domain === cartItems[0]?.domain
    ); // use proper name
    setIsProductCategoryIsDifferent(!everyEnvHasSameValue);
  };

  useEffect(() => {
    checkDistinctProviders();
    checkAvailableQuantity();
    checkDifferentCategory();
    formatCartItems();
  }, [cartItems.length, deliveryAddressLocation, cartItems]);

  const emptyCartScreen = () => {
    return (
      <div className={classes.emptyCartScreen}>
        <InfoOutlinedIcon
          color="warning"
          sx={{ fontSize: 90, marginBottom: 2 }}
        />
        <Typography
          variant="h3"
          sx={{ fontFamily: "Inter", fontWeight: 700, textTransform: "none" }}
        >
          Your Cart is Empty. Please add items
        </Typography>
        <Typography variant="body" sx={{ marginTop: 2, marginBottom: 2 }}>
          Explore our wide selection and find something you like
        </Typography>
        <Link to="/application/products">
          <Button variant="contained">Explore Now</Button>
        </Link>
      </div>
    );
  };

  const renderTableHeads = () => {
    return (
      <Grid>
        <Grid container sx={{ paddingTop: "20px" }}>
          <Grid item xs={4.3}>
            <Typography variant="body1" className={classes.tableHead}>
              Item
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              variant="body1"
              className={classes.tableHead}
              sx={{ marginLeft: "6px" }}
            >
              Price
            </Typography>
          </Grid>
          <Grid item xs={1.2}>
            <Typography
              variant="body1"
              className={classes.tableHead}
              sx={{ marginLeft: "12px" }}
            >
              Qty
            </Typography>
          </Grid>
          <Grid item xs={1.4}>
            <Typography variant="body1" className={classes.tableHead}>
              Subtotal
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" className={classes.tableHead}>
              Special Instructions
            </Typography>
          </Grid>
        </Grid>
        <Divider
          sx={{ borderColor: "#616161", margin: "20px 0", width: "98.5%" }}
        />
      </Grid>
    );
  };

  const renderTableHeadForCheckoutPage = () => {
    return (
      <Grid xs={14}>
        <Grid container>
          <Grid item xs={5.5}>
            <Typography variant="body1" className={classes.tableHead}>
              Item
            </Typography>
          </Grid>
          <Grid item xs={1.3}>
            <Typography
              variant="body1"
              className={classes.tableHead}
              sx={{ marginLeft: "6px" }}
            >
              Price
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography
              variant="body1"
              className={classes.tableHead}
              sx={{ marginLeft: "12px" }}
            >
              Qty
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body1" className={classes.tableHead}>
              Subtotal
            </Typography>
          </Grid>
          {/* <Grid item xs={4}>
            <Typography variant="body1" className={classes.tableHead}>
              Special Instructions
            </Typography>
          </Grid> */}
        </Grid>
        <Divider
          sx={{ borderColor: "#616161", margin: "20px 0", width: "98.5%" }}
        />
      </Grid>
    );
  };

  const getCustomizations = (cartItem) => {
    if (cartItem?.customisations) {
      // const customisations = cartItem?.customisations;
      const customisations = cartItem?.customisations;

      return customisations.map((c, idx) => {
        const isLastItem = idx === customisations?.length - 1;
        return (
          <Grid container>
            <Typography variant="subtitle1" color="#686868">
              {c.item_details.descriptor.name} (₹{c.item_details.price.value}){" "}
              {isLastItem ? "" : "+"}
            </Typography>
          </Grid>
        );
      });
    }

    return null;
  };

  const getPriceWithCustomisations = (cartItem) => {
    let basePrice = cartItem?.product?.price?.value;
    let price = 0;
    cartItem?.item?.customisations?.map(
      (c) => (price += cartItem?.product?.price?.value)
    );

    return basePrice + price;
  };

  const renderSpecialInstructions = (item, id) => {
    const cartItem = cartItems.find((ci) => ci?.id == id);
    const hasSpecialInstructions = cartItem?.customisations?.find((c) => {
      if (c.hasOwnProperty("special_instructions")) {
        return c;
      }
    });

    const handleChange = (e) => {
      const updatedCart = [...cartItems];
      const itemIndex = updatedCart.findIndex((ci) => ci._id === id);

      let itemToUpdate = updatedCart[itemIndex];
      itemToUpdate.item.customisations[0].special_instructions = e.target.value;
      updatedCart[itemIndex] = itemToUpdate;
      setCartItems(updatedCart);
    };
    if (!hasSpecialInstructions) return "NA";
    return (
      <div style={{ position: "relative" }}>
        <TextField
          fullWidth
          multiline
          rows={3}
          size="small"
          placeholder="Write here"
          sx={{ padding: "2px 4px" }}
          value={hasSpecialInstructions?.special_instructions}
          onChange={handleChange}
        />
        <Button
          className={classes.updateBtn}
          variant="text"
          size="small"
          onClick={() => {
            updateCartItem(id, null, id);
          }}
        >
          Update
        </Button>
      </div>
    );
  };

  const renderVegNonVegTag = (cartItem) => {
    const FnB = "ONDC:RET11";
    const grocery = "ONDC:RET10";

    if (cartItem?.domain == grocery || cartItem?.domain == FnB) {
      const tags = cartItem?.tags;
      console.log("tags =>", tags);
      let category = "veg";

      for (let i = 0; i < tags?.length; i++) {
        if (tags[i].code === "veg_nonveg") {
          const vegNonVegValue = tags[i].list[0].value;

          if (vegNonVegValue === "yes" || vegNonVegValue === "Yes") {
            category = "veg";
          } else if (vegNonVegValue === "no") {
            category = "nonveg";
          } else if (vegNonVegValue === "egg") {
            category = "egg";
          }
        }
      }

      const getTagColor = () => {
        if (category === "veg") {
          return "#008001";
        } else if (category === "nonveg") {
          return "red";
        } else {
          return "#008001";
        }
      };

      return (
        <Grid container alignItems="center" className={classes.tagContainer}>
          <div
            className={classes.square}
            style={{ borderColor: getTagColor() }}
          >
            <div
              className={classes.circle}
              style={{ backgroundColor: getTagColor() }}
            ></div>
          </div>
        </Grid>
      );
    }

    return null;
  };

  const renderProducts = () => {
    console.log("formattedCartItems :>", formattedCartItems);
    console.log("providers :>", providers);
    const checkSymbol = (symbol) => {
      return symbol ? symbol : defaultCatImg;
    };
    return (
      <div>
        {formattedCartItems?.length &&
          formattedCartItems?.map(({ providerId, items }, formatInd) => {
            return (
              <Accordion
                key={providerId}
                defaultExpanded={formatInd === 0}
                style={{ padding: "0 10px" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography
                    style={{ marginLeft: "5px" }}
                    variant="body1"
                    className={classes.tableHead}
                  >
                    {`STORE : ${validateValue(providers[providerId])}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {items?.length
                    ? items?.map((cartItem, ind) => (
                        <Grid key={cartItem?.id}>
                          <Grid
                            container
                            key={cartItem?.id}
                            style={{ alignItems: "flex-start" }}
                          >
                            <Grid item xs={4.3}>
                              <Grid container>
                                <div className={classes.moreImages}>
                                  <div className={classes.greyContainer}>
                                    <img
                                      className={classes.moreImage}
                                      alt="product-image"
                                      src={
                                        cartItem?.descriptor?.images[0] ||
                                        defaultCatImg
                                      }
                                      onClick={() =>
                                        history.push(
                                          `/application/products?productId=${cartItem?.id}`
                                        )
                                      }
                                    />
                                    {renderVegNonVegTag(cartItem)}
                                  </div>
                                </div>
                                <Grid sx={{ maxWidth: "200px" }}>
                                  <Typography
                                    variant="body1"
                                    sx={{ width: 200, fontWeight: 600 }}
                                  >
                                    {validateValue(
                                      cartItem?.product?.descriptor?.name
                                    )}
                                  </Typography>
                                  {getCustomizations(cartItem)}
                                  {cartItem?.hasCustomisations && (
                                    <Grid
                                      container
                                      sx={{
                                        marginTop: "4px",
                                        width: "max-content",
                                        cursor: "pointer",
                                      }}
                                      alignItems="center"
                                      onClick={() => {
                                        setCustomizationState(
                                          cartItem?.customisationState
                                        );
                                        getProductDetails(cartItem?.id);
                                        setCurrentCartItem(cartItem);
                                        setOpenDrawer(true);
                                      }}
                                    >
                                      <EditOutlinedIcon
                                        size="small"
                                        sx={{
                                          color: "#196AAB",
                                          fontSize: 16,
                                          marginRight: "5px",
                                        }}
                                      />
                                      <Typography
                                        variant="subtitle1"
                                        color="#196AAB"
                                      >
                                        Customise
                                      </Typography>
                                    </Grid>
                                  )}
                                  <Grid
                                    container
                                    sx={{ marginTop: "4px" }}
                                    alignItems="center"
                                  >
                                    <div className={classes.logoContainer}>
                                      <img
                                        className={classes.logo}
                                        alt={"store-logo"}
                                        src={checkSymbol(
                                          cartItem?.provider?.images[0]
                                        )}
                                      />
                                    </div>
                                    <Typography
                                      variant="subtitle1"
                                      color="#686868"
                                      sx={{ fontWeight: 500 }}
                                    >
                                      {cartItem?.provider?.descriptor?.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {/* {getCustomizations(cartItem)} */}
                            </Grid>
                            <Grid item xs={1}>
                              <Typography
                                variant="body"
                                sx={{ fontWeight: 600 }}
                              >
                                {cartItem?.hasCustomisations
                                  ? `₹ ${getPriceWithCustomisations(cartItem)}`
                                  : `₹ ${validateValue(
                                      cartItem?.product?.price?.value
                                    )}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={1.2}>
                              <div className={classes.qtyContainer}>
                                <Typography
                                  variant="body1"
                                  sx={{ marginRight: "12px", fontWeight: 600 }}
                                >
                                  {cartItem?.quantity?.count}
                                </Typography>
                                <KeyboardArrowUpIcon
                                  className={classes.qtyArrowUp}
                                  onClick={async () =>
                                    // updateCartItem(cartItem?.id, true, cartItem?._id)
                                    {
                                      if (
                                        +cartItem?.quantity?.count <
                                        +cartItem?.product?.quantity?.maximum
                                          ?.count
                                      ) {
                                        const res = await xUpdateCartItem(
                                          cartItem?.id,
                                          "increase"
                                        );
                                        if (res) {
                                          dispatch({
                                            type: toast_actions.ADD_TOAST,
                                            payload: {
                                              id: Math.floor(
                                                Math.random() * 100
                                              ),
                                              type: toast_types.success,
                                              message:
                                                "Item updated in cart successfully.",
                                            },
                                          });
                                        }
                                      } else {
                                        dispatchError(
                                          `Maximum allowed quantity is ${cartItem?.product?.quantity?.maximum?.count}`
                                        );
                                      }
                                    }
                                  }
                                />
                                <KeyboardArrowDownIcon
                                  className={classes.qtyArrowDown}
                                  onClick={async () => {
                                    // updateCartItem(cartItem?.id, false, cartItem?._id)
                                    if (+cartItem?.quantity?.count >= 1) {
                                      const res = await xUpdateCartItem(
                                        cartItem?.id,
                                        "decrease"
                                      );
                                      if (res) {
                                        dispatch({
                                          type: toast_actions.ADD_TOAST,
                                          payload: {
                                            id: Math.floor(Math.random() * 100),
                                            type: toast_types.success,
                                            message:
                                              "Item updated in cart successfully.",
                                          },
                                        });
                                      }
                                    } else {
                                      dispatchError(
                                        `Item quantity is ${cartItem?.quantity?.count}`
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={1.4}>
                              <Typography
                                variant="body"
                                sx={{ fontWeight: 600 }}
                              >
                                {cartItem?.hasCustomisations
                                  ? `₹ ${
                                      parseInt(
                                        getPriceWithCustomisations(cartItem)
                                      ) * parseInt(cartItem?.quantity?.count)
                                    }`
                                  : `₹ ${parseInt(
                                      cartItem?.product?.subtotal
                                    )}`}
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              {renderSpecialInstructions(
                                cartItem,
                                cartItem?.id
                              )}

                              <Grid
                                container
                                sx={{ margin: "16px 0" }}
                                alignItems="center"
                                justifyContent="flex-end"
                              >
                                <Button
                                  variant="text"
                                  startIcon={<DeleteOutlineIcon size="small" />}
                                  color="error"
                                  onClick={async () => {
                                    // deleteCartItem(cartItem?._id ? cartItem?._id : cartItem?.id)
                                    const res = await xDeleteCartItem(
                                      cartItem?.id
                                    );
                                    if (res === true) {
                                      dispatch({
                                        type: toast_actions.ADD_TOAST,
                                        payload: {
                                          id: Math.floor(Math.random() * 100),
                                          type: toast_types.success,
                                          message:
                                            "Item deleted from cart successfully.",
                                        },
                                      });
                                    }
                                  }}
                                >
                                  <Typography>Delete</Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                          {cartItem?.quantity?.count >
                            cartItem?.product?.quantity?.available?.count && (
                            <Grid>
                              <div className={classes.infoBox}>
                                <Typography className={classes.infoText}>
                                  Only{" "}
                                  {
                                    cartItem?.product?.quantity?.available
                                      ?.count
                                  }{" "}
                                  available instead of{" "}
                                  {cartItem?.quantity?.count}. Update the
                                  quantity or switch to another provider.
                                </Typography>
                              </div>
                            </Grid>
                          )}
                          <Divider
                            sx={{
                              borderColor: "#616161",
                              margin: "20px 0",
                              width: "98.5%",
                            }}
                          />
                        </Grid>
                      ))
                    : "yes"}
                </AccordionDetails>
              </Accordion>
            );
          })}
        {haveDistinctProviders && (
          <Grid>
            <div
              className={classes.infoBox}
              style={{ background: "#FAE1E1", width: "98.5%" }}
            >
              <Typography
                className={classes.infoText}
                style={{ color: "#D83232", textAlign: "center" }}
              >
                You are ordering from different store. Please check your order
                again.
              </Typography>
            </div>
          </Grid>
        )}
        {isProductCategoryIsDifferent && (
          <Grid>
            <div
              className={classes.infoBox}
              style={{ background: "#FAE1E1", width: "98.5%" }}
            >
              <Typography
                className={classes.infoText}
                style={{ color: "#D83232", textAlign: "center" }}
              >
                You are ordering from different category. Please check your
                order again.
              </Typography>
            </div>
          </Grid>
        )}
      </div>
    );
  };

  const renderProductsForCheckoutPage = () => {
    console.log("cartItems ::", cartItems);
    return (
      <>
        {cartItems?.length
          ? cartItems?.map((cartItem, idx) => {
              return (
                <Grid key={cartItem["id"]}>
                  <Grid container style={{ alignItems: "flex-start" }}>
                    <Grid item xs={5.5}>
                      <Grid container>
                        <div className={classes.moreImages}>
                          <div className={classes.greyContainer}>
                            <img
                              className={classes.moreImage}
                              alt="product-image"
                              src={cartItem?.product?.descriptor?.symbol}
                              onClick={() =>
                                history.push(
                                  `/application/products?productId=${cartItem?.id}`
                                )
                              }
                            />
                            {renderVegNonVegTag(cartItem)}
                          </div>
                        </div>
                        <Grid sx={{ maxWidth: "200px" }}>
                          <Typography
                            variant="body1"
                            sx={{ width: 200, fontWeight: 600 }}
                          >
                            {cartItem?.product?.descriptor?.name}
                          </Typography>
                          {getCustomizations(cartItem)}
                          {cartItem?.hasCustomisations && (
                            <Grid
                              container
                              sx={{
                                marginTop: "4px",
                                width: "max-content",
                                cursor: "pointer",
                              }}
                              alignItems="center"
                              onClick={() => {
                                setCustomizationState(
                                  cartItem?.customisationState
                                );
                                getProductDetails(cartItem?.id);
                                setCurrentCartItem(cartItem);
                                setOpenDrawer(true);
                              }}
                            >
                              <EditOutlinedIcon
                                size="small"
                                sx={{
                                  color: "#196AAB",
                                  fontSize: 16,
                                  marginRight: "5px",
                                }}
                              />
                              <Typography variant="subtitle1" color="#196AAB">
                                Customise
                              </Typography>
                            </Grid>
                          )}
                          <Grid
                            container
                            sx={{ marginTop: "4px" }}
                            alignItems="center"
                          >
                            <div className={classes.logoContainer}>
                              <img
                                className={classes.logo}
                                alt={"store-logo"}
                                src={cartItem?.provider?.symbol}
                              />
                            </div>
                            <Typography
                              variant="subtitle1"
                              color="#686868"
                              sx={{ fontWeight: 500 }}
                            >
                              {cartItem?.provider?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* {getCustomizations(cartItem)} */}
                    </Grid>
                    <Grid item xs={1.3}>
                      <Typography variant="body" sx={{ fontWeight: 600 }}>
                        {cartItem?.hasCustomisations
                          ? `₹ ${getPriceWithCustomisations(cartItem)}`
                          : `₹ ${cartItem?.product?.price?.value}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <div className={classes.qtyContainer}>
                        <Typography
                          variant="body1"
                          sx={{ marginRight: "12px", fontWeight: 600 }}
                        >
                          {cartItem?.quantity?.count}
                        </Typography>
                        <KeyboardArrowUpIcon
                          className={classes.qtyArrowUp}
                          onClick={async () => {
                            // updateCartItem(cartItem?.id, true, cartItem._id)
                            const res = await xUpdateCartItem(
                              cartItem?.id,
                              "increase"
                            );
                            if (res === true) {
                              dispatch({
                                type: toast_actions.ADD_TOAST,
                                payload: {
                                  id: Math.floor(Math.random() * 100),
                                  type: toast_types.success,
                                  message: "Item updated in cart successfully.",
                                },
                              });
                            }
                          }}
                        />
                        <KeyboardArrowDownIcon
                          className={classes.qtyArrowDown}
                          onClick={async () => {
                            // updateCartItem(cartItem?.id, true, cartItem._id)
                            const res = await xUpdateCartItem(
                              cartItem?.id,
                              "decrease"
                            );
                            if (res === true) {
                              dispatch({
                                type: toast_actions.ADD_TOAST,
                                payload: {
                                  id: Math.floor(Math.random() * 100),
                                  type: toast_types.success,
                                  message: "Item updated in cart successfully.",
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={1.4}>
                      <Typography variant="body" sx={{ fontWeight: 600 }}>
                        {cartItem?.hasCustomisations
                          ? `₹ ${
                              parseInt(getPriceWithCustomisations(cartItem)) *
                              parseInt(cartItem?.quantity?.count)
                            }`
                          : `₹ ${parseInt(cartItem?.product?.subtotal)}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={1.3}>
                      {/* {renderSpecialInstructions(cartItem.item, cartItem._id)} */}
                      <div style={{ marginTop: -10 }}>
                        <Button
                          variant="text"
                          startIcon={<DeleteOutlineIcon size="small" />}
                          color="error"
                          onClick={() =>
                            deleteCartItem(cartItem?._id || cartItem?.id)
                          }
                        >
                          <Typography>Delete</Typography>
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                  {cartItem?.quantity.count >
                    cartItem?.product.quantity.available.count && (
                    <Grid>
                      <div className={classes.infoBox}>
                        <Typography className={classes.infoText}>
                          Only {cartItem?.product?.quantity?.available?.count}{" "}
                          available instead of {cartItem?.quantity?.count}.
                          Update the quantity or switch to another provider.
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {idx === cartItems.length - 1 && haveDistinctProviders && (
                    <Grid>
                      <div
                        className={classes.infoBox}
                        style={{ background: "#FAE1E1", width: "98.5%" }}
                      >
                        <Typography
                          className={classes.infoText}
                          style={{ color: "#D83232", textAlign: "center" }}
                        >
                          You are ordering from different store. Please check
                          your order again.
                        </Typography>
                      </div>
                    </Grid>
                  )}
                  {idx === cartItems.length - 1 &&
                    isProductCategoryIsDifferent && (
                      <Grid>
                        <div
                          className={classes.infoBox}
                          style={{ background: "#FAE1E1", width: "98.5%" }}
                        >
                          <Typography
                            className={classes.infoText}
                            style={{ color: "#D83232", textAlign: "center" }}
                          >
                            You are ordering from different category. Please
                            check your order again.
                          </Typography>
                        </div>
                      </Grid>
                    )}
                  {cartItems.length > 1 && (
                    <Divider
                      sx={{
                        borderColor: "#616161",
                        margin: "20px 0",
                        width: "98.5%",
                      }}
                    />
                  )}
                </Grid>
              );
            })
          : ""}
      </>
    );
  };

  const renderSummaryCard = () => {
    const checkoutCart = async () => {
      try {
        setCheckoutLoading(true);
        var deliveryAddress = getValueFromCookie("delivery_address");
        console.log("delivery_address :>", deliveryAddress);
        deliveryAddress = deliveryAddress ? JSON.parse(deliveryAddress) : null;
        if (deliveryAddress) {
          const {
            location: {
              address: {
                region: { latitude, longitude },
                zipcode,
              },
            },
          } = deliveryAddress;
          const params = {
            items: cartItems?.map((el) => ({
              id: el?.id,
              quantity: el?.quantity?.count,
            })),
            user: user?.id,
            userAddress: {
              region: {
                latitude,
                longitude,
              },
              zipcode,
            },
          };
          console.log("params ;;;10", params);
          console.log("user --->", user);
          // debugger;
          const url = `${baseUrl}/api/select`;
          const res = await postCall(url, params);
          console.log("checkoutCart :>", res);

          if (res?.status) {
            const {
              result: {
                message: {
                  ack: { status: resStatus },
                },
              },
            } = res;
            if (resStatus === "NACK") {
              setCheckoutLoading(false);
              dispatch({
                type: toast_actions.ADD_TOAST,
                payload: {
                  id: Math.floor(Math.random() * 100),
                  type: toast_types.error,
                  message: resStatus?.error?.message,
                },
              });
            }
            if (resStatus === "ACK") {
              history.push(`/application/checkout`);
            }
          } else if (res?.error?.message) {
            setCheckoutLoading(false);
            dispatch({
              type: toast_actions.ADD_TOAST,
              payload: {
                id: Math.floor(Math.random() * 100),
                type: toast_types.error,
                message: res?.error?.message,
              },
            });
          }
        }
      } catch (error) {
        console.log("Error at checkoutCart :>", error);
      }
    };
    return (
      <Card className={classes.summaryCard}>
        <Typography variant="h4" className={classes.summaryTypo}>
          Summary
        </Typography>
        <Divider sx={{ borderColor: "#616161", margin: "20px 0" }} />
        <Grid
          container
          justifyContent="space-between"
          sx={{ marginBottom: "14px" }}
        >
          <Typography variant="subtitle1" className={classes.summaryLabel}>
            Cart Subtotal
          </Typography>
          <Typography variant="subtitle1" className={classes.summaryLabel}>
            ₹{getCartSubtotal()}
          </Typography>
        </Grid>
        <Button
          variant="contained"
          sx={{ marginTop: 1, marginBottom: 2 }}
          disabled={
            isProductAvailableQuantityIsZero ||
            isProductCategoryIsDifferent ||
            haveDistinctProviders ||
            checkoutLoading
          }
          onClick={() => {
            console.log(cartItems?.length);
            if (cartItems?.length > 0) {
              checkoutCart();
            }
          }}
        >
          {checkoutLoading ? <Loading /> : "Checkout"}
        </Button>
      </Card>
    );
  };

  const getProviderIds = (request_object) => {
    let providers = [];
    request_object.map((cartItem) => {
      providers.push(cartItem.provider.local_id);
    });
    const ids = [...new Set(providers)];
    AddCookie("providerIds", ids);
    return ids;
  };

  const getQuote = async (items, searchContextData = null) => {
    const ttansactionId = localStorage.getItem("transaction_id");
    responseRef.current = [];
    if (deliveryAddress) {
      console.log("select req:", deliveryAddress.location.address.lat);
      try {
        setCheckoutLoading(true);
        const search_context =
          searchContextData || JSON.parse(getValueFromCookie("search_context"));
        let domain = "";
        let contextCity = "";
        const updatedItems = items.map((item) => {
          const newItem = Object.assign({}, item);
          domain = newItem.domain;
          contextCity = newItem.contextCity;
          delete newItem.context;
          delete newItem.contextCity;
          return newItem;
        });
        let selectPayload = {
          context: {
            transaction_id: ttansactionId,
            domain: domain,
            city: contextCity || deliveryAddress.location.address.city,
            pincode: JSON.parse(getValueFromCookie("delivery_address"))
              ?.location.address.areaCode,
            state: deliveryAddress.location.address.state,
          },
          message: {
            cart: {
              items: updatedItems,
            },
            fulfillments: [
              {
                end: {
                  location: {
                    gps: `${deliveryAddress?.location?.address?.lat},${deliveryAddress?.location?.address?.lng}`,
                    address: {
                      area_code: `${search_context?.location?.pincode}`,
                    },
                  },
                },
              },
            ],
          },
        };
        const data = await cancellablePromise(
          postCall("/clientApis/v2/select", [selectPayload])
        );
        //Error handling workflow eg, NACK
        const isNACK = data.find(
          (item) => item.error && item?.message?.ack?.status === "NACK"
        );
        if (isNACK) {
          setCheckoutLoading(false);
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: isNACK.error.message,
            },
          });
          setGetQuoteLoading(false);
        } else {
          // fetch through events
          onFetchQuote(
            data?.map((txn) => {
              const { context } = txn;
              return context?.message_id;
            })
          );
        }
      } catch (err) {
        dispatch({
          type: toast_actions.ADD_TOAST,
          payload: {
            id: Math.floor(Math.random() * 100),
            type: toast_types.error,
            message: err?.response?.data?.error?.message,
          },
        });
        setGetQuoteLoading(false);
        history.replace("/application/products");
        setCheckoutLoading(false);
      }
    } else {
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: "Please select address",
        },
      });
      setCheckoutLoading(false);
    }

    // eslint-disable-next-line
  };

  function onFetchQuote(message_id) {
    eventTimeOutRef.current = [];

    const token = getValueFromCookie("token");
    let header = {
      headers: {
        ...(token && {
          Authorization: `Bearer ${token}`,
        }),
      },
    };
    message_id.forEach((id) => {
      let es = new window.EventSourcePolyfill(
        `${process.env.REACT_APP_BASE_URL}clientApis/events/v2?messageId=${id}`,
        header
      );
      es.addEventListener("on_select", (e) => {
        const { messageId } = JSON.parse(e.data);

        onGetQuote(messageId);
      });
      const timer = setTimeout(() => {
        eventTimeOutRef.current.forEach(({ eventSource, timer }) => {
          eventSource.close();
          clearTimeout(timer);
        });
        if (responseRef.current.length <= 0) {
          setGetQuoteLoading(false);
          setCheckoutLoading(false);
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: "Cannot fetch details for this product",
            },
          });
          history.replace("/application/products");
          return;
        } else {
        }
        let c = cartItems.map((item) => {
          return item.item;
        });
        const request_object = constructQouteObject(c);
        if (responseRef.current.length !== request_object.length) {
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message:
                "Cannot fetch details for some product those products will be ignored!",
            },
          });
          setErrorMessageTimeOut("Cannot fetch details for this product");
        }
        setToggleInit(true);
      }, SSE_TIMEOUT);

      eventTimeOutRef.current = [
        ...eventTimeOutRef.current,
        {
          eventSource: es,
          timer,
        },
      ];

      // history.push(`/application/checkout`);
    });
  }

  const onGetQuote = async (message_id) => {
    try {
      setCheckoutLoading(true);
      const data = await cancellablePromise(
        getCall(`/clientApis/v2/on_select?messageIds=${message_id}`)
      );
      responseRef.current = [...responseRef.current, data[0]];

      setEventData((eventData) => [...eventData, data[0]]);

      // onUpdateProduct(data[0].message.quote.items, data[0].message.quote.fulfillments);
      data[0].message.quote.items.forEach((item) => {
        const findItemIndexFromCart = updatedCartItems.current.findIndex(
          (prod) => prod.item.product.id === item.id
        );
        if (findItemIndexFromCart > -1) {
          updatedCartItems.current[
            findItemIndexFromCart
          ].item.product.fulfillment_id = item.fulfillment_id;
          updatedCartItems.current[
            findItemIndexFromCart
          ].item.product.fulfillments = data[0].message.quote.fulfillments;
        }
      });

      localStorage.setItem(
        "cartItems",
        JSON.stringify(updatedCartItems.current)
      );
      localStorage.setItem(
        "updatedCartItems",
        JSON.stringify(responseRef.current)
      );
      history.push(`/application/checkout`);
    } catch (err) {
      setCheckoutLoading(false);
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.error,
          message: err.message,
        },
      });
      setGetQuoteLoading(false);
    }
    // eslint-disable-next-line
  };

  return (
    <div>
      {!showOnlyItems && (
        <div className={classes.headingContainer}>
          <Typography variant="h3" className={classes.heading}>
            My Cart
          </Typography>
        </div>
      )}
      {loading ? (
        <div className={classes.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          {cartItems.length === 0 ? (
            emptyCartScreen()
          ) : (
            <div>
              {!showOnlyItems ? (
                <Grid container className={classes.cartContainer}>
                  <Grid item xs={8} p={2}>
                    {renderTableHeads()}
                    <div
                      style={{
                        minHeight: "80vh",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      {renderProducts()}
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    {renderSummaryCard()}
                  </Grid>
                </Grid>
              ) : (
                <div>
                  {renderTableHeadForCheckoutPage()}
                  <div
                    style={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    {renderProductsForCheckoutPage()}
                  </div>
                </div>
              )}
              <Drawer
                anchor={"right"}
                open={openDrawer}
                onClose={() => {
                  setProductPayload(null);
                  setCustomizationState({});
                  setOpenDrawer(false);
                }}
              >
                <EditCustomizations
                  cartItems={cartItems}
                  productPayload={productPayload}
                  setProductPayload={setProductPayload}
                  customization_state={customization_state}
                  setCustomizationState={setCustomizationState}
                  setOpenDrawer={setOpenDrawer}
                  currentCartItem={currentCartItem}
                />
              </Drawer>
            </div>
          )}
        </>
      )}
    </div>
  );
}
