import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    insert: (state, { action, payload }) => {
      state.value = [...payload];
      console.log("items in store :>", state.value);
    },
    reset: (state) => {
      state.value = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { insert, reset } = productsSlice.actions;

export default productsSlice.reducer;
