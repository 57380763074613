import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "../../shared/button/button";
import { postCall } from "../../../api/axios";
import { getUserDetails } from "../../../api/address.api";
import { buttonTypes } from "../../shared/button/utils";
import ErrorMessage from "../../shared/error-message/errorMessage";

const OtpInputCard = ({ phoneNum, getOTP, toggleOtpModal, handleRedirect }) => {
  const [otpValue, setotpValue] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const inputRef = useRef(new Array(6)?.fill("*"));
  const [isValidOtp, setisValidOtp] = useState(true);
  const handleChange = (e, ind) => {
    e.preventDefault();
    const { value, name } = e?.target;
    setisValidOtp(true);
    setotpValue((pre) => {
      let response = { ...pre, [name]: value };
      Object.values(response);
      console.log("response __", Object.values(response)?.join(""));
      return response;
    });
    if (value) {
      if (ind < 5) inputRef.current[ind + 1].focus();
    }
  };

  // const updateQueryParams = (catName) => {
  //   const params = new URLSearchParams({});
  //   params.set("c", catName);
  // };

  const verifyOTP = async () => {
    setisLoading(true);
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/api/login`;
      const params = {
        phone: `+91${phoneNum}`,
        otp: `${Object.values(otpValue)?.join("")}`,
      };
      const res = await postCall(url, params);
      if (res?.success) {
        var { token, user } = res;
        if (token && user) {
          // directly redirecting to sellers page upon login
          handleRedirect({ token, userId: user?._id });
        }
      } else {
        setisValidOtp(false);
      }
    } catch (error) {
      console.log("Error at getOTP ::", error);
    }
    setisLoading(false);
  };

  useEffect(() => {
    inputRef.current[0].focus();
  }, []);

  return (
    <div class="relative flex min-h-screen flex-col overflow-hidden bg-gray-50 sm:py-6 py-12">
      <div class="relative bg-white sm:px-1 md:px-6 lg:px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl">
        <div class="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div class="flex flex-col items-center justify-center text-center space-y-2">
            <div class="font-semibold text-3xl">
              <p>Mobile Verification</p>
            </div>
            <div class="flex flex-row text-sm font-medium text-gray-400">
              <p>
                We have sent a code to your mobile number{" "}
                {`XXXXXXX${phoneNum?.slice(-3)}`}
              </p>
            </div>
          </div>

          <div>
            <form action="" method="post" class="w-full">
              <div class="flex flex-col space-y-16">
                <div class="flex flex-row items-center justify-between mx-auto w-full max-w-sm">
                  {new Array(6)?.fill("*")?.map((item, ind) => (
                    <div
                      key={ind}
                      class="w-10 h-10 sm:w-14 sm:h-14 md:w-14 md:h-14 lg:w-14 lg:h-14"
                    >
                      <input
                        maxLength={1}
                        defaultValue={otpValue[ind] ? otpValue[ind] : ""}
                        onChange={(e) => handleChange(e, ind)}
                        class="text-black w-full h-full flex flex-col items-center justify-center text-center  outline-none rounded-md sm:rounded-md md:rounded-lg lg:rounded-xl border border-gray-200 sm:text-md text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                        type="text"
                        name={`${ind}`}
                        ref={(ref) => (inputRef.current[ind] = ref)}
                      />
                    </div>
                  ))}
                </div>

                <div class="flex flex-col space-y-2">
                  <div className="w-100 flex justify-center">
                    {/* <button
                      
                      class="flex flex-row items-center justify-center text-center w-full border sm:rounded-lg md:rounded-lg lg:rounded-xl outline-none py-2.5 bg-blue-700 border-none text-white text-sm shadow-sm
                    "
                    >
                      Verify Account
                    </button> */}

                    <Button
                      disabled={Object.values(otpValue)?.join("")?.length !== 6}
                      isloading={isLoading ? 1 : 0}
                      button_type={buttonTypes.primary}
                      button_hover_type={buttonTypes.primary_hover}
                      button_text="Verify Account"
                      type="button"
                      onClick={() => {
                        verifyOTP();
                      }}
                    />
                  </div>
                  {!isValidOtp ? (
                    <div className="w-100 flex flex-row justify-center">
                      <ErrorMessage fontSize="16px">
                        Please enter valid otp
                      </ErrorMessage>
                    </div>
                  ) : (
                    ""
                  )}

                  <div class="flex flex-row justify-center items-start text-sm font-medium space-x-1 text-gray-500">
                    <p>Didn't recieve code?</p>{" "}
                    <a
                      class="flex flex-row items-center text-blue-600"
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        getOTP();
                      }}
                    >
                      Resend
                    </a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpInputCard;
