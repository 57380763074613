import React from "react";
import styles from "./errorMessage.module.scss";

export default function ErrorMessage(props) {
  console.log("props ", props);
  return (
    <div
      className={`${styles.error_message_text} px-2 py-1 text-xl`}
      style={{ ...(props?.fontSize && { fontSize: props?.fontSize }) }}
    >
      {props.children}
    </div>
  );
}
