import { baseUrl } from "./apis";
import { getCall, postCall } from "./axios";

/**
 * function to get all products
 * @returns
 */
export const getAllOrdersRequest = (paginationData) => {
  const pageNumber = paginationData.page;
  const limit = paginationData.pageSize;
  const state = paginationData.status;
  return new Promise(async (resolve, reject) => {
    try {
      const quaryParams = `?limit=${limit}&pageNumber=${pageNumber}&state=${state}`;
      const data = await getCall(`/clientApis/v2/orders${quaryParams}`);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getOrderDetailsRequest = (orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await getCall(`/clientApis/v2/orders/${orderId}`);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getAllOrders = (userID, paginationModel) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/api/get-buyer-order`;
      const params = {
        userId: userID,
        limit: paginationModel ? paginationModel?.pageSize : 20,
        skip: 0,
      };
      const data = await postCall(url, params);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getOrderInfo = (orderId, paginationModel) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/api/get-order-detail`;
      const params = {
        _id: orderId,
      };
      const data = await postCall(url, params);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const getOrderStatus = (orderId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/api/status`;
      const params = {
        orderId: orderId,
      };
      const data = await postCall(url, params);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};

export const cancelOrder = (orderId, reason) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/api/cancel`;
      const params = {
        orderId: orderId,
        cancellationReason: reason || "NA",
      };
      const data = await postCall(url, params);
      return resolve(data);
    } catch (err) {
      return reject(err);
    }
  });
};
