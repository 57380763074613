import React from "react";
import useStyles from "./style";
import { useHistory, useLocation, useParams } from "react-router-dom";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import no_image_found from "../../../assets/images/no_image_found.png";
import { AddCookie } from "../../../utils/cookies";

const SingleBrand = ({ data, onMouseOver }) => {
  // const { id, provider, domain, provider_descriptor } = data;
  // const { name: brand_name, images } = provider_descriptor;
  const {
    _id: id,
    name: brand_name,
    photo: symbol,
    context: { domain, bpp_id: provider },
  } = data;
  const images = [data["photo"]];

  const classes = useStyles();
  const history = useHistory();

  const redirectBasedOnDomain = () => {
    AddCookie("seller", JSON.stringify(data));
    console.log("domain ++", domain);
    // return;
    // if (domain === "ONDC:RET11") {
    //   history.push(`/application/brand?brandId=${provider}&outletId=${id}`);
    // } else {
    //   history.push(`/application/brand?brandId=${provider}`);
    // }
    history.push(`/application/brand?brandId=${provider}`);
  };

  return (
    <div
      className={classes.brandItemContainer}
      onMouseOver={onMouseOver}
      onClick={() => redirectBasedOnDomain()}
    >
      <Card className={classes.brandCard}>
        <img
          className={classes.brandImage}
          src={images?.length > 0 ? images[0] : no_image_found}
          alt={`sub-cat-img-${brand_name}`}
        />
      </Card>
      <Typography
        component="div"
        variant="body"
        className={classes.brandNameTypo}
      >
        {brand_name || ""}
      </Typography>
    </div>
  );
};

export default SingleBrand;
