import React, { useState, useEffect, useContext } from "react";
import useStyles from "./style";

import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CartIcon } from "../../../assets/images/cart.svg";
import no_image_found from "../../../assets/images/no_image_found.png";
import { useHistory } from "react-router-dom";
import { postCall } from "../../../api/axios";
import { AddCookie, getValueFromCookie } from "../../../utils/cookies";
import { Button, ButtonGroup } from "@mui/material";
import { setInLocalStringified } from "../../../utils/localStore";
import { validateValue } from "../../../utils/validateValues";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import { ToastContext } from "../../../context/toastContext";
import { CartContext } from "../../../context/cartContext";
import Loading from "../../shared/loading/loading";

const measurementUnits = {
  kilogram: "kg",
  miligram: "mg",
  litre: "ltr",
  gram: "gm",
  unit: "units",
};

const ProductGridView = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [itemOutOfStock, setItemOutOfStock] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [productAvailability, setProductAvailability] = useState(true);
  const dispatch = useContext(ToastContext);
  const [productPayload, setProductPayload] = useState(null);
  const [customization_state, setCustomizationState] = useState({});
  const [customizationPrices, setCustomizationPrices] = useState(0);
  const [isItemAvailableInCart, setIsItemAvailableInCart] = useState(false);
  const [itemAvailableInCart, setItemAvailableInCart] = useState(null);

  const {
    fetchCartItems,
    cartItems,
    setCartItems,
    xUpdateCartItem,
    xDeleteCartItem,
  } = useContext(CartContext);

  const {
    product,
    productId,
    price,
    bpp_id,
    location_id,
    bpp_provider_id,
    bpp_provider_descriptor,
    show_quantity_button = true,
    onUpdateCart = () => {},
    handleAddToCart = () => {},
    getProductDetails,
    productLoading,
  } = props;
  const {
    _id: id,
    store: { name: provider_name },
    store: provider_details,
    raw: { descriptor },
  } = product;
  // const { name: provider_name } = bpp_provider_descriptor;
  const { name: product_name, images, symbol } = descriptor;
  const {
    raw: {
      quantity: {
        unitized: {
          measure: { unit, value },
        },
      },
    },
  } = product;

  let selectedCustomizationIds = [];

  const getCustomization_ = (groupId) => {
    let group = customization_state[groupId];
    if (!group) return;

    let customizations = group.selected.map((s) =>
      selectedCustomizationIds.push(s.id)
    );
    group?.childs?.map((child) => {
      getCustomization_(child);
    });
  };

  const getCustomizations = () => {
    const { customisation_items } = productPayload;

    if (!customisation_items || !customisation_items?.length) return null;
    const customizations = [];

    const firstGroupId = customization_state["firstGroup"]?.id;

    if (!firstGroupId) return;
    selectedCustomizationIds = [];
    getCustomization_(firstGroupId);

    for (const cId of selectedCustomizationIds) {
      let c = customisation_items.find((item) => item.local_id === cId);
      if (c) {
        c = {
          ...c,
          quantity: {
            count: 1,
          },
        };
        customizations.push(c);
      }
    }

    return customizations;
  };

  const calculateSubtotal = (groupId, customization_state) => {
    let group = customization_state[groupId];
    if (!group) return;

    let prices = group.selected.map((s) => s.price);
    setCustomizationPrices((prevState) => {
      return prevState + prices.reduce((a, b) => a + b, 0);
    });

    group?.childs?.map((child) => {
      calculateSubtotal(child, customization_state);
    });
  };

  const addToCart = async (navigate = false, isIncrement = true) => {
    setAddToCartLoading(true);
    let subtotal = product?.raw?.price?.value;

    const customisations = getCustomizations() ?? null;

    if (customisations) {
      calculateSubtotal(
        customization_state["firstGroup"]?.id,
        customization_state
      );
      subtotal += customizationPrices;
    }

    const payload = {
      ...productPayload?.raw,
      id: productPayload?._id,
      local_id: productPayload?.raw?.location_id,
      bpp_id: productPayload?.context?.bpp_id,
      bpp_uri: productPayload.context.bpp_uri,
      domain: productPayload.context.domain,
      tags: productPayload?.raw?.tags,
      customisationState: customization_state,
      contextCity: productPayload.context.city,
      quantity: {
        count: 1,
      },
      provider: {
        id: productPayload?.context?.bpp_id,
        locations: productPayload?.locations ? productPayload?.locations : [],
        ...productPayload?.store,
      },
      product: {
        id: productPayload?.productId,
        subtotal,
        ...productPayload?.raw?.descriptor,
        ...productPayload?.raw,
      },
      customisations,
      hasCustomisations: customisations ? true : false,
    };

    const cartItem = cartItems?.find(
      (item) => item?.id === productPayload?._id
    );
    console.log(
      "productPayload?._id : ",
      productPayload?._id,
      " cartItem : ",
      cartItem,
      " cartItems : ",
      cartItems
    );

    if (cartItems.length === 0 || !cartItem) {
      setInLocalStringified("cart", [...cartItems, { ...payload }]);
      setCartItems((pre) => [...cartItems, { ...payload }]);
      setAddToCartLoading(false);
      dispatch({
        type: toast_actions.ADD_TOAST,
        payload: {
          id: Math.floor(Math.random() * 100),
          type: toast_types.success,
          message: "Item added to cart successfully.",
        },
      });
    } else {
      // const currentCount = parseInt(cartItem[0].item.quantity.count);
      let tarItem = {};
      if (!!cartItem && Object.values(cartItem)?.length) {
        const {
          id,
          quantity: { count: currentCount },
        } = cartItem;
        const {
          raw: {
            quantity: {
              available: { count: maxCount },
            },
          },
        } = productPayload;
        if (currentCount < maxCount) {
          const upd_res = await xUpdateCartItem(
            id,
            isIncrement ? "increase" : "decrease"
          );
          console.log("update res: ", upd_res);
          setAddToCartLoading(false);
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.success,
              message: "Item quantity updated in your cart.",
            },
          });
        } else {
          setAddToCartLoading(false);
          dispatch({
            type: toast_actions.ADD_TOAST,
            payload: {
              id: Math.floor(Math.random() * 100),
              type: toast_types.error,
              message: `The maximum available quantity for item is already in your cart.`,
            },
          });
        }
      }
    }
    if (navigate) {
      console.log("navigate ::>", navigate);
      debugger;
      history.push("/application/cart");
    }
  };

  const deleteCartItem = async (itemId) => {
    const upd_res = await xDeleteCartItem(itemId);
    console.log("delete res: ", upd_res);
    fetchCartItems();
  };

  const checkProductDisability = (data) => {
    const itemTags = data?.raw?.time?.label;
    const providerTags = data.provider_details?.raw?.time?.label || "enable"; //
    const locationTags = data.location_details?.raw?.time?.label || "enable"; //

    const isItemEnabled = itemTags === "enable";
    const isProviderEnabled = providerTags === "enable";
    const isLocationEnabled = locationTags === "enable";

    if (isItemEnabled || isProviderEnabled || isLocationEnabled) {
      setProductAvailability(true);
    } else {
      setProductAvailability(false);
    }
  };

  useEffect(() => {
    console.log("product getting 1::::", product);
    if (product) {
      console.log("product getting 2::::", product);
      setProductPayload(product);
      checkProductDisability(product);
    }
  }, [cartItems]);

  useEffect(() => {
    console.log("cartItems 000 :>", cartItems);
    console.log("productPayload 000 :>", productPayload);
    if (
      productPayload &&
      productPayload?._id &&
      cartItems &&
      cartItems.length > 0
    ) {
      let isItemAvailable = false;
      let findItem = null;
      if (productPayload?.context.domain === "ONDC:RET11") {
        findItem = cartItems.find((item) => item?.id === productPayload?._id);
      } else {
        findItem = cartItems.find((item) => item?.id === productPayload?._id);
      }
      console.log("findItem 1:>", findItem);
      if (findItem) {
        console.log("findItem :>", findItem);
        isItemAvailable = true;
        setItemAvailableInCart(findItem);
      } else {
      }
      setIsItemAvailableInCart(isItemAvailable);
    } else {
      setItemAvailableInCart(null);
      setIsItemAvailableInCart(false);
    }
  }, [cartItems, customization_state, productPayload]);

  console.log("itemOutOfStock", itemOutOfStock);
  console.log("productAvailability", productAvailability);

  return (
    <div>
      {true ? (
        <div className={classes.productItemContainer}>
          <div
            onClick={() => {
              setInLocalStringified("product", product);
              history.push(`/application/products?productId=${productId}`);
            }}
          >
            <Card className={classes.productCard}>
              <img
                className={classes.productImage}
                src={symbol ? symbol : no_image_found}
                alt={`sub-cat-img-${bpp_id}`}
              />
              <Tooltip title="Add to cart">
                {/* <IconButton
              onClick={(e) => {
                e.stopPropagation();
                // getProductDetails(productId).then((data) => handleAddToCart(data, true));
              }}
              color="inherit"
              className={classes.cartIcon}
            >
              <CartIcon />
            </IconButton> */}
              </Tooltip>
              {/* <Button
            fullWidth
            className={classes.buyNowButton}
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();
              // getProductDetails(productId).then((data) => handleAddToCart(data, true, true));
            }}
          >
            Buy Now
          </Button> */}
            </Card>
            <Typography
              component="div"
              variant="body"
              className={classes.productNameTypo}
            >
              {validateValue(product_name)}
            </Typography>
            <Typography
              component="div"
              variant="body"
              className={classes.productNameTypo}
            >
              {`${validateValue(value)}${validateValue(
                measurementUnits[unit.toLowerCase()]
              )}`}
            </Typography>
            <Typography variant="body1" className={classes.providerTypo}>
              {validateValue(provider_name)}
            </Typography>
            <Box component={"div"} className={classes.divider} />
            <Typography variant="h5" className={classes.priceTypo}>
              {`₹${
                Number.isInteger(Number(price))
                  ? validateValue(Number(price).toFixed(2))
                  : validateValue(Number(price).toFixed(2))
              }`}
            </Typography>
          </div>
          <div style={{ marginTop: "0.75rem", width: "50%", height: "30px" }}>
            {
              // productPayload?.context.domain !== "ONDC:RET11" &&
              isItemAvailableInCart && itemAvailableInCart ? (
                <ButtonGroup
                  variant={"contained"}
                  fullWidth
                  color="primary"
                  sx={{
                    flex: 1,
                    marginRight: "16px",
                    textTransform: "none",
                    borderRadius: "18px",
                  }}
                  disabled={
                    !parseInt(product?.raw?.quantity?.available?.count) >= 1 ||
                    itemOutOfStock ||
                    addToCartLoading
                  }
                >
                  <Button
                    onClick={() => addToCart(false, true)}
                    sx={{ fontSize: "24px !important" }}
                  >
                    +
                  </Button>
                  <Button
                    variant={"outlined"}
                    sx={{ fontSize: "20px !important" }}
                  >
                    {addToCartLoading ? (
                      <Loading />
                    ) : (
                      itemAvailableInCart?.quantity?.count
                    )}
                  </Button>
                  <Button
                    onClick={() => {
                      if (itemAvailableInCart?.quantity?.count === 1) {
                        deleteCartItem(itemAvailableInCart?.id);
                      } else {
                        addToCart(false, false);
                      }
                    }}
                    sx={{ fontSize: "30px !important" }}
                  >
                    -
                  </Button>
                </ButtonGroup>
              ) : (
                <Button
                  variant={"contained"}
                  sx={{
                    flex: 1,
                    marginRight: "16px",
                    textTransform: "none",
                  }}
                  onClick={() => addToCart(false, true)}
                  disabled={
                    !parseInt(product?.raw?.quantity?.available?.count) >= 1 ||
                    itemOutOfStock ||
                    addToCartLoading ||
                    !productAvailability
                  }
                >
                  {addToCartLoading ? <Loading /> : "Add to cart"}
                </Button>
              )
            }
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductGridView;
