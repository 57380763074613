import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    isLoading: false,
    data: [],
  },
};

export const sellersSlice = createSlice({
  name: "sellers",
  initialState,
  reducers: {
    insert: (state, { action, payload }) => {
      state.value = { ...state?.value, data: [...payload] };
    },
    loadingTrue: (state, { action, payload }) => {
      state.value = { ...state?.value, isLoading: true };
    },
    loadingFalse: (state, { action, payload }) => {
      state.value = { ...state?.value, isLoading: false };
    },
  },
});

// Action creators are generated for each case reducer function
export const { insert, loadingTrue, loadingFalse } = sellersSlice.actions;

export default sellersSlice.reducer;
