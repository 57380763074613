import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Products from "../../products/products";
import ProductDetails from "./product-details/productDetails";
import { getFromLocalParsed } from "../../../utils/localStore";

const ProductRoutes = () => {
  const locationData = useLocation();
  const useQuery = () => {
    const { search } = locationData;
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };
  let query = useQuery();

  const [productId, setProductId] = useState("");

  const checkLocalProduct = () => {
    const xProduct = getFromLocalParsed("product");
    let retVal = null;
    if (xProduct?.productId) {
      console.log("locale productId :", xProduct?.productId);
      query.set("productId", xProduct?.productId);
      retVal = xProduct?.productId;
    }
    return retVal;
  };

  useEffect(() => {
    if (locationData) {
      const productId = query.get("productId");
      console.log("productId :", productId);
      setProductId(productId);
    }
  }, [locationData]);

  if (productId) {
    return <ProductDetails productId={productId} />;
  } else {
    return <Products />;
  }
};

export default ProductRoutes;
