import React, { useContext } from "react";
import useStyles from "./style";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "../../common/Radio";
import { AddressContext } from "../../../context/addressContext";
import { AddCookie, removeCookie } from "../../../utils/cookies";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { validateValue } from "../../../utils/validateValues";
import { validate } from "uuid";

const AddressList = ({
  addressList,
  label,
  setAddAddress,
  setUpdateAddress,
  onSelectAddress,
}) => {
  const classes = useStyles();
  const { deliveryAddress, setDeliveryAddress, setBillingAddress } =
    useContext(AddressContext);

  const onSetDeliveryAddress = (id, descriptor, address) => {
    return {
      id,
      name: descriptor?.contactName || "",
      email: descriptor?.emailId || "",
      phone: descriptor?.contactPhone || "",
      location: {
        ...descriptor.region,
        building: descriptor?.address_line1,
        locality: descriptor?.address_line2,
        city: descriptor?.city,
        state: descriptor?.state,
        landmark: descriptor?.landmark,
        country: descriptor?.country,
        area_code: descriptor?.zipcode,
      },
    };
  };

  console.log("addressList ::>", addressList);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography className={classes.addressFormLabelTypo} variant="body">
          {label}
        </Typography>
      </Grid>
      {addressList &&
        addressList.length > 0 &&
        addressList.map((delivery_address, ind) => {
          const {
            _id: id,
            contactName: name,
            landmark: address,
            addressId,
          } = delivery_address;
          return (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.selectAddressRadioContainer}
              key={`address-ind-${ind}`}
            >
              <FormControlLabel
                className={classes.formControlLabel}
                onClick={() => {
                  if (deliveryAddress?.id !== id) {
                    setDeliveryAddress(() =>
                      onSetDeliveryAddress(id, delivery_address, address)
                    );
                    AddCookie(
                      "delivery_address",
                      JSON.stringify(
                        onSetDeliveryAddress(id, delivery_address, address)
                      )
                    );
                    setBillingAddress();
                    removeCookie("billing_address");
                    onSelectAddress();
                  }
                }}
                control={
                  <Radio
                    checked={deliveryAddress?.id === delivery_address["_id"]}
                  />
                }
                label={
                  <div className={classes.addressTypoContainer}>
                    <Typography
                      variant="body"
                      component="div"
                      className={classes.addressNameTypo}
                    >
                      {validateValue(`${delivery_address?.contactName}`)}
                    </Typography>
                    <Typography
                      variant="body1"
                      component="div"
                      className={classes.addressTypo}
                    >
                      {`${
                        delivery_address?.address_line1
                          ? validateValue(delivery_address.address_line1)
                          : validateValue(delivery_address?.address_line2)
                      }, ${validateValue(
                        delivery_address?.city
                      )}, ${validateValue(
                        delivery_address?.state
                      )}, Pin Code: ${validateValue(
                        delivery_address?.zipcode
                      )} ${validateValue(delivery_address?.country)}`}
                      <br />
                      {`phone: ${delivery_address?.contactPhone}`}
                    </Typography>
                    <div className={classes.addressActionContainer}>
                      <EditIcon
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setUpdateAddress({
                            id,
                            addressId,
                            name: delivery_address?.contactName,
                            email: delivery_address?.emailId,
                            phone: delivery_address?.contactPhone,
                            areaCode: delivery_address?.zipcode,
                            city: delivery_address?.city,
                            door: delivery_address?.address_line1,
                            state: delivery_address?.state,
                            street: delivery_address?.address_line2,
                            tag: delivery_address?.landmark,
                          });
                        }}
                      />
                    </div>
                  </div>
                }
              />
            </Grid>
          );
        })}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        className={classes.addAddress}
        onClick={setAddAddress}
      >
        <Typography
          variant="body"
          component="div"
          className={classes.addAddressTypo}
          color="primary.main"
        >
          <AddCircleOutlineRoundedIcon className={classes.addAddressIcon} />
          Add new address
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddressList;
