import React, { useContext } from "react";
import useStyles from "./style";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { AddressContext } from "../../../context/addressContext";
import { validateValue } from "../../../utils/validateValues";

const StepAddressLabel = ({ activeStep, onUpdateActiveStep }) => {
  const classes = useStyles();
  const { deliveryAddress, billingAddress } = useContext(AddressContext);

  console.log("billingAddress ===}", billingAddress);
  console.log("deliveryAddress ===}", deliveryAddress);

  return (
    <div>
      <Typography variant="h4" className={classes.labelTypo}>
        Add Address
        {activeStep !== 3 && (
          <Button
            className={classes.editAddress}
            variant="text"
            color="primary"
            onClick={() => onUpdateActiveStep()}
          >
            EDIT
          </Button>
        )}
      </Typography>
      {activeStep > 2 && billingAddress && deliveryAddress && (
        <div className={classes.addressLabelTypo}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography
                className={classes.addressHeaderTypo}
                component="div"
                variant="body"
              >
                Billing Address
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(billingAddress?.name)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {billingAddress?.phone}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {`${validateValue(
                  billingAddress?.address?.address_line1
                )}, ${validateValue(
                  billingAddress?.address?.address?.address_line2
                )}`}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(billingAddress?.address?.address?.city)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(billingAddress?.address?.address?.state)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(billingAddress?.address?.address?.country)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(billingAddress?.address?.address?.zipcode)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography
                className={classes.addressHeaderTypo}
                component="div"
                variant="body"
              >
                Shipping Address
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(deliveryAddress?.name)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(deliveryAddress?.phone)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {`${validateValue(
                  deliveryAddress?.location?.address?.address_line1
                )}, ${deliveryAddress?.location?.address?.address_line2}`}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(deliveryAddress?.location?.address?.city)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(deliveryAddress?.location?.address?.state)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(deliveryAddress?.location?.address?.country)}
              </Typography>
              <Typography
                className={classes.addressTextTypo}
                component="div"
                variant="body"
              >
                {validateValue(deliveryAddress?.location?.address?.zipcode)}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default StepAddressLabel;
