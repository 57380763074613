import { createContext, useState, useEffect } from "react";
import { getCall, postCall } from "../api/axios";
import { AddCookie, getValueFromCookie } from "../utils/cookies";
import { baseUrl } from "../api/apis";
import { getFromLocalParsed, setInLocalStringified } from "../utils/localStore";

export const CartContext = createContext({
  cartItems: [],
  setCartItems: () => {},
  getCartItems: () => {},
});

export function CartContextProvider({ children }) {
  let user = {};
  const userCookie = getValueFromCookie("user");

  if (userCookie) {
    try {
      user = JSON.parse(userCookie);
    } catch (error) {
      console.log("Error parsing user cookie:", error);
    }
  }
  const [cartItems, setCartItems] = useState([]);

  const getCartItems = async () => {
    try {
      // mayank-mohak it is not working not with user id not with d07c5f35-a542-4f49-b5d2-3c40c4396849
      const url = `${baseUrl}/api/cart`;
      const params = {
        messageId: user?.userId ? user?.userId : "hsgJH_Hj4",
      };
      const res = await postCall(url, params);
      const cartCookie = getValueFromCookie("cart");
      let localeCart = getFromLocalParsed("cart");
      let cartValue =
        res && !!res?.data && res?.data?.length
          ? res?.data
          : localeCart && localeCart?.length
          ? localeCart
          : [];
      console.log("localeCart :>", localeCart);
      setCartItems((pre) => [...cartValue]);
    } catch (error) {
      console.log("Error fetching cart items:", error);
    }
  };

  const xUpdateCartItem = (itemId, evt) => {
    return new Promise((res, rej) => {
      let curItem = cartItems?.find((item) => item?.id === itemId);
      let {
        quantity: { count: curCount },
      } = curItem;
      curCount =
        evt === "increase"
          ? curCount + 1
          : evt === "decrease"
          ? curCount - 1
          : curCount;
      curItem = { ...curItem, quantity: { count: curCount } };
      let updatedItems = cartItems?.map((item) => {
        let val = item;
        if (item?.id === itemId) {
          val = {
            ...curItem,
            product: {
              ...curItem?.product,
              subtotal: +curItem?.product?.price?.value * curCount,
            },
          };
        }
        return val;
      });
      updateCartFinal(updatedItems, res);
    });
  };

  const xDeleteCartItem = (itemId) => {
    return new Promise((res, rej) => {
      let res_items = cartItems?.filter((el) => el?.id !== itemId);
      updateCartFinal(res_items, res);
    });
  };

  const updateCartFinal = (cart_items, res) => {
    setInLocalStringified("cart", [...cart_items]);
    setCartItems((pre) => [...cart_items]);
    console.log("updatedItems ==>", cart_items);
    res(true);
  };

  useEffect(() => {
    console.log("user::", user);
    if (!!Object.keys(user).length) {
      getCartItems();
    }
    // getCartItems();
  }, []);

  useEffect(() => {
    console.log("cartItems on change ::>", cartItems);
  }, [cartItems]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        setCartItems,
        fetchCartItems: getCartItems,
        xUpdateCartItem,
        xDeleteCartItem,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
