import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
} from "firebase/auth";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import AuthActionCard from "../auth-action-card/authActionCard";
import { Link, useHistory } from "react-router-dom";
import Input from "../../shared/input/input";
import PasswordInput from "../../shared/passwordInput/input";
import ErrorMessage from "../../shared/error-message/errorMessage";
import { toast_actions, toast_types } from "../../shared/toast/utils/toast";
import { getErrorMessage } from "../../../api/utils/mapFirebaseError";
import { AddCookie } from "../../../utils/cookies";
import { ToastContext } from "../../../context/toastContext";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";

import Google_Logo from "../../../assets/images/google.png";
import { isLoggedIn } from "../../../utils/validateToken";
import OtpInputCard from "../Otp-input-card/OtpInputCard";
import ModalComponent from "../../common/Modal";
import { postCall } from "../../../api/axios";
import { baseUrl } from "../../../api/apis";
export default function Login() {
  // const auth = getAuth();
  // const provider = new GoogleAuthProvider();
  const history = useHistory();
  const [otpModal, setOtpModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  // const [email, setEmail] = useState();
  // const [password, setPassword] = useState();
  // const [signInUsingGoogleloading, setSignInUsingGoogleLoading] =
  //   useState(false);
  const dispatch = useContext(ToastContext);
  const [inlineError, setInlineError] = useState({
    email_error: "",
    password_error: "",
    phone_error: "",
  });

  const toggleOtpModal = () => setOtpModal((pre) => !pre);
  // use this function to check the email
  // function checkEmail() {
  //   if (!email) {
  //     setInlineError((inlineError) => ({
  //       ...inlineError,
  //       email_error: "Email cannot be empty",
  //     }));
  //     return false;
  //   }
  //   return true;
  // }

  // function checkPassword() {
  //   if (!password) {
  //     setInlineError((inlineError) => ({
  //       ...inlineError,
  //       password_error: "Password cannot be empty",
  //     }));
  //     return false;
  //   } else if (password && password.length < 8) {
  //     setInlineError((inlineError) => ({
  //       ...inlineError,
  //       password_error: "Password cannot be less than 8 characters",
  //     }));
  //     return false;
  //   }

  //   return true;
  // }

  function checkPhoneNumber() {
    if (validator.isEmpty(phoneNum)) {
      setInlineError((error) => ({
        ...error,
        phone_error: "Mobile number cannot be empty",
      }));
      return false;
    }
    if (!validator.isMobilePhone(phoneNum, "en-IN")) {
      setInlineError((error) => ({
        ...error,
        phone_error: "Please enter a valid Mobile number",
      }));
      return false;
    }
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getOTP();
  };

  const getOTP = async () => {
    setisLoading(true);
    try {
      const url = `${baseUrl}/api/request-otp-v2`;
      const params = {
        phone: `+91${phoneNum}`,
      };
      const res = await postCall(url, params);
      if (res?.status === 200 && res?.success) {
        toggleOtpModal();
      }
    } catch (error) {
      console.log("Error at getOTP ::", error);
    }
    setisLoading(false);
  };

  function handleRedirect(details) {
    localStorage.setItem("transaction_id", uuidv4());
    const { token, userId } = details;
    AddCookie("token", token);
    AddCookie(
      "user",
      JSON.stringify({
        id: userId,
        // name: contactName,
        // email: emailId,
        // photoURL: "",
      })
    );
    history.push("/application");
  }

  // function handleLoginWithEmailAndPassword(e) {
  //   e.preventDefault();
  //   const allCheckPassed = [checkEmail(), checkPassword()].every(Boolean);
  //   if (!allCheckPassed) {
  //     return;
  //   }
  //   setSignInUsingEmailAndPasswordLoading(true);
  // signInWithEmailAndPassword(auth, email, password)
  //     .then((result) => {
  // handleRedirect(result.user);
  //     })
  //     .catch((error) => {
  //       const errorCode = error.code;
  //       const errorMessage = getErrorMessage(errorCode);
  //       dispatch({
  //         type: toast_actions.ADD_TOAST,
  //         payload: {
  //           id: Math.floor(Math.random() * 100),
  //           type: toast_types.error,
  //           message: errorMessage,
  //         },
  //       });
  //     })
  //     .finally(() => setSignInUsingEmailAndPasswordLoading(false));
  // }
  // function handleLoginWithGoogle() {
  //   setSignInUsingGoogleLoading(true);
  //   signInWithPopup(auth, provider)
  //     .then((result) => {
  //       handleRedirect(result.user);
  //     })
  //     .catch((error) => {
  //       if (error.code !== "auth/popup-closed-by-user") {
  //         const errorMessage = error.message;
  //         dispatch({
  //           type: toast_actions.ADD_TOAST,
  //           payload: {
  //             id: Math.floor(Math.random() * 100),
  //             type: toast_types.error,
  //             message: errorMessage,
  //           },
  //         });
  //       }
  //     })
  //     .finally(() => setSignInUsingGoogleLoading(false));
  // }
  const loginForm = (
    <div className={styles.auth_form}>
      <form onSubmit={(e) => handleSubmit(e)}>
        {/* <Input
          id="email"
          name="email"
          type="email"
          placeholder="Enter Email"
          label_name="Email"
          autoComplete="off"
          has_error={inlineError.email_error}
          onChange={(event) => {
            setEmail(event.target.value);
            setInlineError((inlineError) => ({
              ...inlineError,
              email_error: "",
            }));
          }}
          onBlur={checkEmail}
          required
        />
        {inlineError.email_error && (
          <ErrorMessage>{inlineError.email_error}</ErrorMessage>
        )}
        <PasswordInput
          id="password"
          name="password"
          // type="password"
          placeholder="Enter Password"
          label_name="Password"
          autoComplete="off"
          has_error={inlineError.password_error}
          onChange={(event) => {
            setPassword(event.target.value);
            setInlineError((inlineError) => ({
              ...inlineError,
              password_error: "",
            }));
          }}
          onBlur={checkPassword}
          required
        />
        {inlineError.password_error && (
          <ErrorMessage>{inlineError.password_error}</ErrorMessage>
        )} */}

        <Input
          type="text"
          maxLength="10"
          placeholder="Enter Mobile Number"
          id="phone"
          label_name="Mobile Number"
          has_error={inlineError.phone_error}
          onChange={(event) => {
            const regexp = /^[0-9]+$/;
            if (!regexp.test(event.target.value) && event.target.value !== "")
              return;
            setPhoneNum((pre) => event.target.value);
            setInlineError((inlineError) => ({
              ...inlineError,
              phone_error: "",
            }));
          }}
          onBlur={checkPhoneNumber}
          required
        />
        <ErrorMessage>{inlineError.phone_error}</ErrorMessage>

        <div className="py-3 text-center">
          <Button
            isloading={isLoading ? 1 : 0}
            button_type={buttonTypes.primary}
            button_hover_type={buttonTypes.primary_hover}
            button_text="Get OTP"
            type="submit"
          />
        </div>
        <hr style={{ margin: "5px 0", border: "1px solid #ddd" }} />

        <ModalComponent
          open={otpModal}
          fullWidth={true}
          onClose={() => {
            setOtpModal(false);
          }}
          title="Put OTP"
        >
          <OtpInputCard
            phoneNum={phoneNum}
            getOTP={getOTP}
            toggleOtpModal={toggleOtpModal}
            handleRedirect={handleRedirect}
          />
        </ModalComponent>

        {/* <div className="py-3 text-center">
          <Button 
            isloading={signInUsingGoogleloading ? 1 : 0}
            disabled={
              signInUsingGoogleloading || signInUsingEmailAndPasswordloading
            }
            button_type={buttonTypes.primary}
            button_hover_type={buttonTypes.primary_hover}
            button_text={
              <>
                <img
                  src={Google_Logo}
                  alt="logo"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Login with google
              </>
            }
            onClick={handleLoginWithGoogle}
          />
        </div> */}
      </form>
    </div>
  );
  const navigation_link = (
    <div className="py-2 text-center">
      <p className={styles.navigation_link_label}>Don't have an account?</p>
      <Link to="/sign-up" className={styles.navigation_link}>
        Sign Up
      </Link>
    </div>
  );

  if (isLoggedIn()) {
    return <Redirect to={{ pathname: "/application" }} />;
  } else {
    return (
      <AuthActionCard
        action_form={loginForm}
        navigation_link={navigation_link}
      />
    );
  }
}
